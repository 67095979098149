import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { LayoutHeader } from './services/my-layout.service';
import { ReceiptComponent } from './modules/new-reservation/components/receipt/receipt.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  {
    path: 'benchmark',
    loadComponent: () =>
      import('./benchmark').then((_) => _.BenchmarkComponent),
  },
  { path: 'printInvoice', component: ReceiptComponent },

  // Redirect empty path to '/dashboards/project'
  { path: '', pathMatch: 'full', redirectTo: 'board' },

  // Redirect signed-in user to the '/dashboards/project'
  //
  // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  {
    path: 'signed-in-redirect',
    pathMatch: 'full',
    redirectTo: 'board',
  },

  // Auth routes for guests
  {
    path: '',
    canMatch: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [

      {
        path: 'terms-and-forms',
        data: {
          title: 'evaluation_specialists',
          icon: 'heroicons_outline:user-group',
        } as LayoutHeader,
        loadChildren: () =>
          import(
            'app/modules/terms-and-forms/terms-and-forms.module'
            ).then((m) => m.TermsAndFormsModule),
      },
      {
        path: 'confirmation-required',
        loadChildren: () =>
          import(
            'app/modules/auth/confirmation-required/confirmation-required.module'
          ).then((m) => m.AuthConfirmationRequiredModule),
      },
      {
        path: 'forgot-password',
        loadChildren: () =>
          import(
            'app/modules/auth/forgot-password/forgot-password.module'
          ).then((m) => m.AuthForgotPasswordModule),
      },
      {
        path: 'reset-password',
        loadChildren: () =>
          import('app/modules/auth/reset-password/reset-password.module').then(
            (m) => m.AuthResetPasswordModule
          ),
      },
      {
        path: 'sign-in',
        loadChildren: () =>
          import('app/modules/auth/sign-in/sign-in.module').then(
            (m) => m.AuthSignInModule
          ),
      },
      {
        path: 'sign-up',
        loadChildren: () =>
          import('app/modules/auth/sign-up/sign-up.module').then(
            (m) => m.AuthSignUpModule
          ),
      },
    ],
  },

  // Auth routes for authenticated users
  {
    path: '',
    canMatch: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () =>
          import('app/modules/auth/sign-out/sign-out.module').then(
            (m) => m.AuthSignOutModule
          ),
      },
      {
        path: 'unlock-session',
        loadChildren: () =>
          import('app/modules/auth/unlock-session/unlock-session.module').then(
            (m) => m.AuthUnlockSessionModule
          ),
      },
    ],
  },

  // Landing routes
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('app/modules/landing/home/home.module').then(
            (m) => m.LandingHomeModule
          ),
      },
    ],
  },

  // Admin routes
  {
    path: '',
    canMatch: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      // Dashboards
      {
        path: 'new-reservation',
        data: {
          title: 'new_res',
          icon: 'heroicons_outline:plus-circle',
        } as LayoutHeader,
        loadChildren: () =>
          import('app/modules/new-reservation/new-reservation.module').then(
            (m) => m.NewReservationModule
          ),
      },
      {
        path: 'board',
        data: {
          title: 'res_status',
          icon: 'heroicons_outline:view-boards',
        } as LayoutHeader,
        loadChildren: () =>
          import('app/modules/today-board/today-board.module').then(
            (m) => m.TodayBoardModule
          ),
      },
      {
        path: 'staff',
        data: {
          title: 'evaluation_specialists',
          icon: 'heroicons_outline:user-group',
        } as LayoutHeader,
        loadChildren: () =>
          import(
            'app/modules/staff-services-board/staff-services-board.module'
          ).then((m) => m.StaffServicesBoardModule),
      },
      {
        path: 'terms-and-form',
        data: {
          title: 'evaluation_specialists',
          icon: 'heroicons_outline:user-group',
        } as LayoutHeader,
        loadChildren: () =>
          import(
            'app/modules/terms-and-forms/terms-and-forms.module'
          ).then((m) => m.TermsAndFormsModule),
      },

      // 404 & Catch all
      {
        path: '404-not-found',
        pathMatch: 'full',
        loadChildren: () =>
          import('app/modules/error/error-404/error-404.module').then(
            (m) => m.Error404Module
          ),
      },
      { path: '**', redirectTo: '404-not-found' },
    ],
  },
];
