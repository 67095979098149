import { Injectable } from '@angular/core';
import { ProfileService } from './profile.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ApiResponse } from '../models/api/ApiResponse.Interface';
import { environment } from '../../environments/environment';
import {
  BehaviorSubject,
  catchError,
  lastValueFrom,
  map,
  Observable,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';
import { ReservationDetails } from '../models/api/reservation.interface';
import { FuseConfirmationService } from '../../@fuse/services/confirmation';
import { PrintInvoiceService } from './print-invoice.service';
import { MatDialog } from '@angular/material/dialog';
import { CancelReservationDialog } from 'app/modules/today-board/dialogs/cancel-reservation/cancel-reservation.dialog';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class ReservationsService {
  constructor(
    private _profile: ProfileService,
    private _httpClient: HttpClient,
    private _fuseConfirmation: FuseConfirmationService,
    private _printInvoiceService: PrintInvoiceService,
    private _toastr: ToastrService,
    public dialog: MatDialog,
    private readonly _translate: TranslocoService
  ) {}

  reservations: any[];
  reservationsServices: any[];

  getReservations(dateStr) {
    const querystring = dateStr ? '?date=' + dateStr : '';
    return this._httpClient
      .get<ApiResponse<any[]>>(
        environment.api_url + '/booking-status' + querystring
      )
      .pipe(
        tap(async (response) => {
          if (response.status) {
            this.reservations = response.data;
          }
        }),
        map((e) => e.data),
        catchError((e) => {
          this._toastr.warning(e.error.message);
          throw e;
        })
      );
  }

  getReservationDetails(reservationId: string): Observable<ReservationDetails> {
    return this._httpClient
      .get<ApiResponse<{ bookings: ReservationDetails }>>(
        environment.api_url + '/bookings/' + reservationId
      )
      .pipe(
        tap((response) => {
          // this.prevReservation = response.data.bookings;
          // this.formBuilder()
        }),
        map((e) => e.data.bookings)
      );
  }

  updateStatus(id: number, status: string) {
    return this._httpClient
      .post<ApiResponse<any>>(environment.api_url + '/update-status/' + id, {
        status: Number(status),
      })
      .pipe(
        tap(async (response) => {
          if (response.status) {
            this.reservations = response.data;
          }
        }),
        map((e) => e.data),
        catchError((e) => {
          this._toastr.warning(e.error.message);
          throw e;
        })
      );
  }

  updateStatusAndStaff(id: number, staff: any, status: string = undefined) {
    return this._httpClient
      .post<ApiResponse<any>>(environment.api_url + '/update-status/' + id, {
        services: staff,
        status,
      })
      .pipe(
        tap(async (response) => {
          if (response.status) {
            this.reservations = response.data;
          }
        }),
        map((e) => e.data),
        catchError((e) => {
          this._toastr.warning(e.error.message);
          throw e;
        })
      );
  }

  public updateReservationStatus = (payload: any): Observable<unknown> => {
    const id = payload?.id;
    delete payload.id;

    return this._httpClient
      .post<ApiResponse<any>>(
        environment.api_url + '/update-status/' + id,
        payload
      )
      .pipe(
        tap((res: any) => (this.reservations = res.data)),
        catchError((err) => {
          this._toastr.warning(err?.error.message);
          return throwError(() => err);
        })
      );
  };

  public updateStatusCancel = (
    res: ReservationDetails
  ): Observable<unknown> => {
    const status = '4';
    const subject = new BehaviorSubject(false);

    this.dialog
      .open(CancelReservationDialog, {
        data: res,
        disableClose: true,
        minWidth: '50%',
        minHeight: '100px',
        maxHeight: '90vh',
      })
      .afterClosed()
      .subscribe(async (result) => {
        if (result.action === 'submit') {
          // GStatic.logImp('Reservation was cancelled with reason:', result.reason);

          const response = await lastValueFrom(
            this._httpClient.post<ApiResponse<any>>(
              environment.api_url + '/update-status/' + res?.id,
              {
                status: Number(status),
                cancel_reason: result.reason,
                notes: result.reason,
                comment: result.reason,
                refundMethod: +result.refundMethod,
              }
            )
          ).catch((e) => {
            this._toastr.warning(e.error.message);
            throw e;
          });
          if (response.status) {
            this.reservations = response.data;
          }
          subject.next(true);
        }
      });

    return subject.asObservable();
  };

  updateStatusRefund(id: number, amount: string) {
    const status = '8';
    const subject = new BehaviorSubject(false);

    const dialogRef = this._fuseConfirmation.open({
      title: 'are_you_sure_var',
      message: this._translate.translate(
        'amount_will_canceled_refunded_msg_var',
        { var: amount }
      ),
      actions: {
        confirm: {
          color: 'primary',
          label: 'refund',
        },
        cancel: { label: 'close' },
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === 'confirmed') {
        const response = await lastValueFrom(
          this._httpClient.post<ApiResponse<any>>(
            environment.api_url + '/update-status/' + id,
            {
              status: Number(status),
            }
          )
        ).catch((e) => {
          this._toastr.warning(e.error.message);
          throw e;
        });
        if (response.status) {
          this.reservations = response.data;
        }
        subject.next(true);
      }
    });

    return subject.asObservable();
  }

  sendInvoiceSms(id: number) {
    return this._httpClient
      .post<ApiResponse<any>>(environment.api_url + '/send-sms/' + id, {})
      .pipe(
        tap((response) => {
          this._toastr.info(response.message);
        })
      );
  }

  sendConfirmationSms(id: number) {
    return this._httpClient
      .post<ApiResponse<any>>(
        environment.api_url + '/confirm-message/' + id,
        {}
      )
      .pipe(
        tap((response) => {
          this._toastr.info(response.message);
        })
      );
  }

  sendFeedbackSms(id: number) {
    return this._httpClient
      .post<ApiResponse<any>>(environment.api_url + '/feedback/' + id, {})
      .pipe(
        tap((response) => {
          this._toastr.info(response.message);
        })
      );
  }

  sendTermsSms(id: number) {
    return this._httpClient
      .post<ApiResponse<any>>(environment.api_url + '/send-terms-sms/' + id, {})
      .pipe(
        tap((response) => {
          this._toastr.info(response.message);
        })
      );
  }

  getReservationsServices(date = null) {
    let querystring = date ? '?date=' + date : ''; //'?date=2023-05-30'

    return this._httpClient
      .get<ApiResponse<any[]>>(
        environment.api_url + '/booking-staff' + querystring
      )
      .pipe(
        tap(async (response) => {
          if (response.status) {
            this.reservationsServices = response.data;
          }
        }),
        map((e) => e.data),
        catchError((e) => {
          this._toastr.warning(e.error.message);
          throw e;
        })
      );
  }

  printInvoice(data: ReservationDetails) {
    this._printInvoiceService.printInvoice(data);
  }

  public addNote = (userId: number, note: string): Observable<any> => {
    return this._httpClient
      .post<ApiResponse<any>>(environment.api_url + '/create-note', {
        user_id: userId,
        note: note.replaceAll('\n', '<br>'),
      })
      .pipe(
        tap((response) => {
          this._toastr.info(response.message);
        })
      );
  };

  countsOnDate(date: string = null, services: boolean = false) {
    let params = new HttpParams();

    if (date) {
      params = params.set('date', date);
    }

    if (services) {
      params = params.set('services', '1');
    }

    return this._httpClient
      .get<ApiResponse<{ count: number; day: string }[]>>(
        environment.api_url + '/count-date',
        { params }
      )
      .pipe(
        map((e) => e.data),
        catchError((e) => {
          this._toastr.warning(e.error.message);
          throw e;
        })
      );
  }

  countsOnTime(date = null) {
    let querystring = date ? '?date=' + date : ''; //'?date=2023-05-30'
    return this._httpClient
      .get<ApiResponse<{ count: number; time: string }[]>>(
        environment.api_url + '/count-time' + querystring
      )
      .pipe(
        map((e) => e.data),
        catchError((e) => {
          this._toastr.warning(e.error.message);
          throw e;
        })
      );
  }
}
